<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-card class="col-12 col-md-6" header-tag="header">
        <template #header>
          <div>
            <!-- กลับไปหน้าเดิม -->
            <b-button variant="danger" @click="() => $router.go(-1)">กลับ</b-button>
          </div>
        </template>
        <!-- <div class="m-1 h4" @click="() => $router.go(-1)" style="cursor: pointer">รายการ{{ this.$store.getters.getPage }} {{ this.total }}</div> -->
        <b-card-text>
          <validation-observer ref="submitForm">
            <b-overlay :show="playLoad" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-wrap />
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="แจ้งไปที่" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="แจ้งไปที่" rules="required">
                      <b-form-select @change="reportValuechang(formTicket.reportValue.departmentId)" v-model="formTicket.reportValue" :options="reportInto" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="ประเภทเเจ้ง" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="ประเภทเเจ้ง" rules="required">
                      <b-form-select :disabled="!formTicket.reportValue" v-model="formTicket.declaredValue" :options="declaredType" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="เรื่อง" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="เรื่อง" rules="required">
                      <b-form-input v-model="formTicket.title" :state="errors.length > 0 ? false : null" placeholder="เรื่อง" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="รายระเอียด" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="รายระเอียด" rules="">
                      <b-form-textarea v-model="formTicket.detall" :state="errors.length > 0 ? false : null" placeholder="รายระเอียด" rows="3" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="ลำดับความสำคัญ" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="ลำดับความสำคัญ" rules="required">
                      <b-form-select
                        v-model="formTicket.priority"
                        :options="[
                          { value: null, text: 'เลือกลำดับความสำคัญ' },
                          { value: 'Low', text: 'Low' },
                          { value: 'Medium', text: 'Medium' },
                          { value: 'High', text: 'High' },
                        ]"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12" label-cols-md="4">
                  <div v-if="formTicket.chatImage.length != 0" class="row">
                    <div class="col-4" v-for="(img, index) in formTicket.chatImage" :key="index">
                      <div class="text-center">
                        <b-img width="75" height="50" :src="imageConvertUrl(img)" variant="light-primary" />
                        <br />
                        <b-button
                          @click="
                            () => {
                              formTicket.chatImage.splice(index, 1);
                            }
                          "
                          class="btn-icon"
                          variant="flat-danger"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="รูปภาพ" label-cols-md="4">
                    <b-form-file v-model="changImage" type="file" @change="handleFileUpload" accept="image/*" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." multiple />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="เลือกตำเเหน่ง" label-cols-md="4">
                    {{ formTicket.gps }}
                  </b-form-group>
                  <!-- {{ center }}
                  <br />
                  {{ formTicket.gps }} -->
                  <l-map :zoom.sync="zoom" :center.sync="center" style="width: auto; height: 250px">
                    <l-tile-layer :url="url" :attribution="attribution" />

                    <l-marker :latLng.sync="formTicket.gps" :draggable="true" />
                    <!-- วงกลม -->
                    <!-- <l-circle :lat-lng="circle.center" :radius="circle.radius" :color="circle.color" /> -->
                  </l-map>
                </b-col>

                <!-- <b-col cols="12">
                  <b-form-group label="วันที่พบ (ว/ด/ป)" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="วันที่พบ" rules="required">
                      <b-form-datepicker
                        v-model="formTicket.date"
                        :state="errors.length > 0 ? false : null"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        placeholder="วันที่พบ"
                        label-today-button="วันนี้"
                        label-reset-button="รีเซ็ต"
                        today-button
                        reset-button
                      >
                      </b-form-datepicker>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="เวลาพบ " label-cols-md="4">
                    <validation-provider #default="{ errors }" name="เวลาพบ" rules="required">
                      <b-form-timepicker v-model="formTicket.time" :state="errors.length > 0 ? false : null" :locale="locale" placeholder="เวลาพบ" label-now-button="เวลานี้" label-reset-button="รีเซ็ต" now-button no-close-button reset-button />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <!-- submit and reset -->
                <b-col offset-md="4" class="mt-1">
                  <b-button @click.prevent="submitForm()" type="submit" variant="primary" class="mr-1"> บันทึก </b-button>
                  <b-button @click="resetForm()" type="reset" variant="outline-secondary"> รีเซ็ต </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
  import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue';

  import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
  import { required } from '@validations';
  import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

  import { Icon } from 'leaflet';
  delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });
  import 'leaflet/dist/leaflet.css';
  import axios from 'axios';
  import API from '@/connect/config';
  

  export default {
    components: {
      StatisticCardHorizontal,
      ValidationProvider,
      ValidationObserver,
      LMap,
      LTileLayer,
      LMarker,
    },
    data() {
      return {
        // ? VeeValidate
        required,
        //? locale
        locale: 'th',
        changImage: [],
        formTicket: {
          title: '', //เรื่อง
          detall: '', //รายระเอียด
          priority: null, //ลำดับความสำคัญ
          reportValue: null, //แจ้งไปที่
          declaredValue: null, //ประเภทเเจ้ง
          gps: [0, 0],
          status: 'Open',
          type: '',
          chatImage: [],
          //   uId: '',
          //   userId: '',
        },
        reportInto: [
          { value: null, text: 'เลือกแจ้งไปที่' }, //แจ้งไปที่
        ],
        declaredType: [
          { value: null, text: 'เลือกประเภทเเจ้ง' }, //ประเภทเเจ้ง
        ],
        total: '',
        // ? map
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 15,
        center: [0, 0],
        // ? loading
        playLoad: false,
      };
    },
    async created() {
      console.log(this.$store.getters.getChang);
      if (!this.$store.getters.getChang) {
        this.$router.go(-1);
      } else {
        // this.$router.beforeEach((to, from) => {
        //   console.log(to, from);
        // });

        localize(this.locale);
        this.localGPS();
        this.formTicket.type = await this.$store.getters.getChang;
        const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        axios.get(`${API}departmentUser/${userId}`).then(async (el) => {
          this.reportInto.push(
            ...el.data.message.result.map((el) => {
              return {
                ...el,
                value: el,
                text: el.department,
              };
            })
          );
        });
      }
    },
    methods: {
      async handleFileUpload(event) {
        console.log(event);
        const file = event.target.files;
        if (file.length == 0) return;

        Array.from(file).forEach((file) => {
          console.log(file);
          // Create an image element
          const img = new Image();
          const reader = new FileReader();
          reader.onload = (e) => {
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
          img.onload = () => {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas size
            const MAX_WIDTH = 600; // Set your desired max width
            const MAX_HEIGHT = 600; // Set your desired max height
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const resizedFile = new File([blob], file.name, { type: 'image/jpeg' }, 1);
                // console.log(resizedFile)
                this.formTicket.chatImage.push(resizedFile);
              },
              'image/jpeg',
              1
            );
          };
        });
      },

      reportValuechang(reportValue) {
        console.log(reportValue);
        // console.log(this.reportInto);
        this.formTicket.declaredValue = null;
        if (reportValue) {
          //this.formTicket.declaredValue = null
          (this.declaredType = [
            { value: null, text: 'เลือกประเภทเเจ้ง' }, //ประเภทเเจ้ง
          ]),
            this.reportInto.forEach((el) => {
              if (el.departmentId == reportValue) {
                this.declaredType.push(
                  ...el.departmentType.map((el2) => {
                    console.log(el2);
                    return {
                      ...el2,
                      text: el2,
                      value: el2,
                    };
                  })
                );
              }
            });
        }
      },
      async submitForm() {
        this.$refs.submitForm.validate().then(async (success) => {
          if (success) {
            this.playLoad = true;
            const { username } = JSON.parse(localStorage.getItem('profile_visitor'));
            const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
            const uId = JSON.parse(localStorage.getItem('profile_visitor')).userId;
            const { title, priority, gps, type, detall, reportValue, declaredValue } = this.formTicket;
            console.log(username);
            const data = {
              userId: userId,
              uId: uId,
              type: type,
              assignee: username, //ผู้รับมอบหมาย
              title: title, //เรื่อง
              description: detall, //รายระเอียด
              priority: priority, //ลำดับความสำคัญ
              gps: {
                latitude: gps.lat ? gps.lat : gps[0],
                longitude: gps.lng ? gps.lng : gps[0],
              },
              department: reportValue.department, //แจ้งไปที่
              departmentType: declaredValue, //ประเภทเเจ้ง
              status: 'Open',
            };
            console.log(data);

            axios
              .post(`${API}ticket`, data)
              .then(async (el) => {
                console.log(el);
                this.getDataTable();
                //this.chat(ticketId.ticketId);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            // re-validate to re-generate the messages.
            this.$refs.submitForm.validate();

            this.playLoad = false;
          }
        });
      },
      async getDataTable() {
        const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        const uId = JSON.parse(localStorage.getItem('profile_visitor')).userId;
        const { type } = this.formTicket;
        console.log(userId, uId);
        var param = userId + '?uId=' + uId + '&type=' + type + '&status=Open%20OR%20In%20Progress&' + '_page=1&_limit=100&_sort=1';

        axios
          .get(`${API}ticket/${param}`)
          .then(async (el) => {
            // console.log(el.data.message.result[el.data.message.result.length - 1]);
            const ticketId = el.data.message.result[el.data.message.result.length - 1];
            const { chatImage } = this.formTicket;
            console.log(chatImage);
            if (chatImage.length != 0) {
              chatImage.forEach(async (el) => {
                await this.chat(ticketId.ticketId, el);
              });
            } else {
              this.$store.commit('setToastMessage', {
                message: `add chat completed`,
                title: 'เพิ่มข้อมูล',
                variant: 'primary',
              });
              this.$router.go(-1);
              this.playLoad = false;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      async chat(ticketId, _fileimg) {
        console.log('---------------------------------------------------');
        const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        const uId = JSON.parse(localStorage.getItem('profile_visitor')).userId;

        const { username } = JSON.parse(localStorage.getItem('profile_visitor'));

        console.log(userId, uId);
        console.log(ticketId);
        console.log(username);
        const _data = new FormData();

        _data.append('userId', userId);
        _data.append('uId', uId);
        _data.append('ticketId', ticketId);
        _data.append('description', 'txt_msg_description');
        _data.append('sender', username);
        //  if (chatImage != null) {
        //  chatImage.forEach((el) => {
        //console.log(el);
        _data.append('chatImage', _fileimg);
        // });
        // }

        axios
          .post(`${API}chat`, _data)
          .then(async (el) => {
            console.log(el);
          })
          .catch((err) => {
            console.log(err.data.message);
          });
        this.$store.commit('setToastMessage', {
          message: `add chat completed`,
          title: 'เพิ่มข้อมูล',
          variant: 'primary',
        });
        this.$router.go(-1);
        this.playLoad = false;
      },

      resetForm() {
        this.formTicket = {
          title: '', //เรื่อง
          description: '', //รายระเอียด
          priority: null, //ลำดับความสำคัญ
          reportValue: null, //แจ้งไปที่
          declaredValue: null, //ประเภทเเจ้ง
          gps: [0, 0],
          status: 'Open',
          type: '',
          chatImage: [],
        };
        this.zoom = 15;
        this.localGPS();
        this.$nextTick(() => {
          this.$refs.submitForm.reset();
        });
      },
      localGPS() {
        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;
          this.formTicket.gps = [latitude, longitude];
          this.center = [latitude, longitude];
        });
      },
      imageConvertUrl(image) {
        if (image) {
          const url = window.URL.createObjectURL(image);
          return url;
        }
      },
    },
  };
</script>

<style></style>
